@media only screen and (max-width: 992px) {
  .about__page-content h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .about__page-img {
    display: none;
  }
}
.setcss{
  visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;
}

h5.solar_center {
  text-align: center;
  /* margin-bottom: 25px; */
  position: relative;
  color: #f80100;
  font-weight: 600;
}

/* h5.solar_center:before {
  position: absolute;
  content: "";
  background-color: #2d9e04;
  height: 7px;
  width: 7px;
  left: 0;
  right: 0;
  bottom: -18px;
  margin: auto;
  z-index: 1;
} */
h5.solar_center.solar_left {
  text-align: left;
}
h5.solar_center.solar_left:before {
  left: 0;
  margin: 0;
}
h5.solar_center.solar_left:after {
  left: 0;
  width: 90px;
  margin: 0;
}
h1.text_span {
  text-align: left;
color: #020a43;
font-weight: 800;
}
.text_span span {
  color: #002a4c;
}
/* btn css */
.solar_btn {
  color: #063862;
  background: linear-gradient(90deg, #f6e057, #f6e057 51%, #f6e057) var(--x, 0)/ 200%;
  height: 40px;
  display: inline-block;
  padding: 0 45px;
  font-weight: 500;
  border-radius: 100px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.solar_btn:hover {
  --x: 100%;
color: #ffffff;
}

/* btn css */
/********************************************************
1. body end
********************************************************/


/* Why Choose Section Css */
.solar_about_two {
	background: linear-gradient(to right, #002a4c, #6abd45);
    padding: 50px 40px;
    margin-bottom: -160px;
	position: relative;
	box-shadow: 0 -10px 10px 0px #0000001f;
}
.solar_about_gradient_left {
    height: 60px;
    background: linear-gradient(to right, #002a4c, #6abd45);
    transform: rotate(0deg) skew(0deg, -2deg);
    width: 100%;
    position: absolute;
    bottom: -20px;
    left: 0;
}
.solar_about_sources, .single__member {
  /* border:1px solid #a2d96a; */
  /* box-shadow: 0 5px 20px -5px rgb(0 0 0 / 50%) !important; */
  border: 3px solid #fff;
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
  border-radius: 12px;
    padding: 10px;
    position: relative;
    z-index: 1;
	background-color: #fff;
	text-align: center;
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition:  all 0.3s ease-in-out;
}
.solar_about_sources.solar_about_mt01,.solar_about_sources.solar_about_mt02{
	margin-bottom: 25px;	
  
}
.about_rotate img{
  width:100%;
  height:100%;
}
h2.text_span {
  text-align: left;
  color: #020a43;
  font-weight: 700;
}
.benifit_img{
  width:70px !important;
  min-height:50px !important;
  max-height: 100px !important;
}
/* .solar_about_sources.solar_about_mt01:after {
    position: absolute;
    content: "";
    border-left: solid 263px #002a4c;
    border-right: solid 0px #00ffff00;
    border-top: solid 0px #00800000;
    border-bottom: solid 44px #0000ff00;
    left: 0;
    bottom: -44px;
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition:  all 0.3s ease-in-out;
}
.solar_about_sources.solar_about_mt02:after {
    position: absolute;
    content: "";
    border-left: solid 263px #002a4c00;
    border-right: solid 263px #002a4c;
    border-top: solid 0px #00800000;
    border-bottom: solid 44px #0000ff00;
    right: 0;
    bottom: -44px;
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition:  all 0.3s ease-in-out;
}
.solar_about_sources.solar_about_mt03:after {
    position: absolute;
    content: "";
    border-left: solid 263px #008dff00;
    border-right: solid 0px #002a4c;
    border-top: solid 0px #008000;
    border-bottom: solid 44px #002a4c;
    right: 0;
    top: -44px;
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition:  all 0.3s ease-in-out;
} */
/* .solar_about_sources.solar_about_mt04:after {
    position: absolute;
    content: "";
    border-left: solid 0px #002a4c;
    border-right: solid 263px #008dff00;
    border-top: solid 0px #008000;
    border-bottom: solid 44px #002a4c;
    right: -1px;
    top: -44px;
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition:  all 0.3s ease-in-out;
} */
/* .solar_about_sources:hover{
    background-color: #5fa639;
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition:  all 0.3s ease-in-out;
} */
.solar_about_sources:hover.solar_about_mt01:after {
	border-left: solid 263px #5fa639;
}
.solar_about_sources:hover.solar_about_mt02:after {
	border-right: solid 263px #5fa639;
}
.solar_about_sources:hover.solar_about_mt03:after,.solar_about_sources:hover.solar_about_mt04:after {
	border-bottom: solid 44px #5fa639;
}
.solar_about_sources h5 {
  margin: 20px 0 10px;
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.solar_about_sources p {
    color: #000;
}
.solar_about_sources:hover:after{
	opacity:1;
	visibility:visible;	
}
.solar_about_sources h4 {
    font-size: 18px;
    margin: 15px 0;
	color: #fff;
}
.solar_Serivces:hover h4, .solar_about_sources p {
	color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition:  all 0.3s ease-in-out;
}
.solar_about_sources img{
  width:auto;
  height:100px;
}
.solar_vison_detail {
  display: flex;
  align-items: center;
  margin: 40px 0 0;
}
.solar_vison_detail .solar_why_choose_icon {
  margin: 0 20px 0 0;
  width: 110px;
  font-size: 60px;
  font-weight: 900;
  /* background: linear-gradient(to bottom, #dad16b 22%, #dad86b 26%, #d8da6b 27%, #d8da6b 40%, #4b9125 88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color:#020a43;
  display: inline-block;
}
.solar_why_choose_icon {
  margin: 0 0 25px;
  /* box-shadow: 0 0px 10px 0px #f0f0f0; */
  /* background-color: #ffffff; */
  padding: 4px;
  border-radius: 5px;
  display: inline-block;
  width: 110px;
  height: 110px;
  transition: 1s;
}
.solar_vison_detail img, .solar_why_choose_detail img {
  transition: 1s;
  width: 50px;
  height:50px;
}
.solar_aboutus_page .solar_why_choose_text {
  width: calc(100% - 145px);
  display: inline-block;
  vertical-align: middle;
}
.solar_why_choose_text h5 {
  margin-bottom: 10px;
  color: #6abd45;
}
.solar_why_choose_text p {
  color: #002a4c;
  font-weight: 600;
}