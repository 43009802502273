.contact_layout{
  background: #fff;
    border-radius: 12px;
    padding: 20px;
    border: 3px solid #fff;
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
}
.contact form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact label {
  display: block;
  margin-bottom: 8px;
}

.contact input, .contact textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact button {

  padding: 10px 15px;
  border-radius: 4px;
}
.company-info{
  position: relative;
  width:100% !important;
  height:100% !important;
}
.company-info iframe{
  width:100% !important;
  height:100% !important;
}
.contact button:hover {
  background-color: #0056b3;
}
