.find__cars-left {
  background: linear-gradient(rgba(2, 2, 2, 0.123), #7bd860),
    url("../assets/all-images/slider-img/admission.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 65px 35px;
  height:300px;
  position: relative;
  border-radius: 6px 0px 0px 6px;
}

.find__cars-left h2 {
  position: absolute;
  bottom: 10px;
  color:#fff;
  font-weight: 600;
  font-size: 1.8rem;
  font-style: italic;
 
  font-weight: 700;
  filter: drop-shadow(-3px -3px 2px rgba(255, 255, 255, .3)) drop-shadow(5px 5px 5px rgba(0, 0, 0, .2)) drop-shadow(5px 5px 5px rgba(0, 0, 0, .2));
}

.form__group {
  width: 30%;
  margin-bottom: 0;
}

.form__group input {
  border: 1px solid #7c8a972a;
  padding: 8px 12px;

  width: 100%;
  outline: none;
}
.form__group input::placeholder {
  color: #7c8a97;
}

.form {
  background: #fff;
  padding-top: 55px;
  padding-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.select__group {
  width: 30%;
}

.select__group select {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #7c8a972a;
  outline: none;
  color: #7c8a97;
}

.hero__slider-section {
  position: relative;
  z-index: 10;
}

.hero__form {
  position: absolute;
  bottom: -50px;
  z-index: 11;
  width: 100%;

 
}
.facts {
  position: relative;
  margin-top: -75px;
  z-index: 1;
}
.box {
  padding: 10px;
  -webkit-box-shadow: 0 15px 30px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 5%);
  background: #fff;
  border-radius: 10px;
}
.box .img {
  /* -webkit-box-flex: 0;
  -ms-flex: 0 0 260px;
  flex: 0 0 260px; */
  margin-right: 50px;
}
.category {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .1rem;
  color:#820101;
}
.box h3 a {
  color: #192f59;
}
.services-2 {
align-items: center;

}
.services-2 .icon span {
  font-size: 30px;
  color: red;
  font-weight: 600;
  background: #fff;
  border-radius: 50%;
  padding:10px;
  display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #f7e057;
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
}
.services-2 .text h3 {
  font-size: 15px;
  font-weight: 700;
  margin-left: 6px;
  margin-top: 10px;
  color:#192f59;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #fff !important;
  font-weight: 600;
  background: #192f59 !important;
}

/* parrallex start */

#section-counter {
  background-image: url(../assets/all-images/dfdf.jpg);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

#section-counter {
  position: relative;
  z-index: 0; }
  #section-counter:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    z-index: -1;
    opacity: .8;
    background: #0d1128;
    background: #0d1128;
    /* background: -moz-linear-gradient(45deg, #0d1128 0%, #fd6100 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, #0d1128), color-stop(100%, #fd6100));
    background: -webkit-linear-gradient(45deg, #0d1128 0%, #fd6100 100%);
    background: -o-linear-gradient(45deg, #0d1128 0%, #fd6100 100%);
    background: -ms-linear-gradient(45deg, #0d1128 0%, #fd6100 100%); */
    background: linear-gradient(rgba(31, 31, 31, 0.535), rgba(33, 33, 33, 0.161)),;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0d1128', endColorstr='#fd6100', GradientType=1 ); }
/* .solar_about_detail.gssd{
color:white !important;
} */
.ftco-counter {
  overflow-x: hidden;
  position: relative; }
  @media (max-width: 1199.98px) {
    .ftco-counter {
      background-position: top center !important; } }
  .ftco-counter .img-video {
    width: 100%;
    display: block; }
    @media (max-width: 767.98px) {
      .ftco-counter .img-video {
        height: 300px; } }
  .ftco-counter .icon span {
    font-size: 50px;
    color: #000000;
    line-height: 1; }
  .ftco-counter .block-18 {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 30px; }
  .ftco-counter .text strong.number {
    font-weight: 600;
    font-size: 40px;
    color: #fff;
    display: block; }
  .ftco-counter .text span {
    display: block;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8); }
  @media (max-width: 767.98px) {
    .ftco-counter .counter-wrap {
      margin-bottom: 20px; } }

.ftco-consult {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative; }
  @media (max-width: 991.98px) {
    .ftco-consult {
      background-position: top center !important; } }
  .ftco-consult .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .7;
    background: #fd6100;
    background: -moz-linear-gradient(45deg, #fd6100 0%, #0d1128 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, #fd6100), color-stop(100%, #0d1128));
    background: -webkit-linear-gradient(45deg, #fd6100 0%, #0d1128 100%);
    background: -o-linear-gradient(45deg, #fd6100 0%, #0d1128 100%);
    background: -ms-linear-gradient(45deg, #fd6100 0%, #0d1128 100%);
    background: linear-gradient(45deg, #fd6100 0%, #0d1128 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fd6100', endColorstr='#0d1128', GradientType=1 ); }

/* parrallex close */

.services-2 .icon {
  line-height: 1;
  position: relative;
  width: 60px;
  height: 60px;
  color:#67ac3f;
  /* background: #020a43; */
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.whatsapp-button{
  position: fixed;
  bottom: 55px;
  right: 15px;
  z-index: 99;
  background-color: #25d366;
  border-radius: 50px;
  color: #ffffff;
  text-decoration: none;
  width: 50px;
  height: 50px;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 25px -6px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 25px -6px rgba(0,0,0,1);
  box-shadow: 0px 0px 25px -6px rgba(0,0,0,1);
  animation: effect 5s infinite ease-in;
}

@keyframes effect {
  20%, 100% {
      width: 50px;
      height: 50px;
      font-size: 30px;
  }
  0%, 10%{
      width: 55px;
      height: 55px;
      font-size: 35px;
  }
  5%{
      width: 50px;
      height: 50px;
      font-size: 30px;
  }
}

.form__row {
  
  box-shadow: 5px 5px 15px -5px #7c8a97;
  
}
.newsletter-section .newsletter-sec {
  background: linear-gradient(90deg,#fdc830,#f37335);
  background-repeat: no-repeat;
  padding:50px;
  border-radius: 40px;
  background-position: 0;
  margin-bottom: -135px;
  position: relative;
  overflow: hidden;
}

.newsletter-section .newsletter-sec:before {
  content: "";
  position: absolute;
  bottom: 46px;
  left: 0;
  width: 59px;
  height: 115px;
  background-image: url(../assets/illustration/news-bg.png);
  background-repeat: no-repeat;
}
.newsletter-section .newsletter-sec:after {
  content: "";
  position: absolute;
  bottom: 15px;
  right: 0;
  width: 78px;
  height: 100px;
  background-image: url(../assets/illustration/circle5.png);
  background-repeat: no-repeat;
}
.newsletter-section .newsletter-sec .newsz-ltr-text>h2 {
  color: #2b2b2b;
  font-size: 36.58px;
  line-height: 44.71px;
  margin-bottom: 33px;
  font-weight: 700;
}
.newsletter-section .newsletter-sec .newsletter-form {
  padding-left: 62px;
}
.newsletter-section .newsletter-sec .newsletter-form .row {
  margin: 0 -10px;
}
.newsletter-section .newsletter-sec .newsletter-form .form-group {
  margin-bottom: 20px;
}
.newsletter-section .newsletter-sec .newsletter-form .form-group input, .newsletter-section .newsletter-sec .newsletter-form .form-group select {
  width: 100%;
  background-color: #fddead;
  height: 40px;
  line-height: 40px;
  color: #575757;
  font-size: 14.23px;
  padding: 0 16px;
  border-radius: 20px;
  border: 0;
  font-style: italic;
}

.newsletter-section .newsletter-sec .newsletter-form .form-group textarea {
  color: #575757;
  font-size: 14.23px;
  background-color: #fddead;
  height: 70px;
  width: 100%;
  border-radius: 20px;
  border: 0;
  resize: none;
  padding: 10px 15px;
}
.btn-default {
  display: inline-block;
  color: #fff;
  font-size: 18.29px;
  font-weight: 500;
  background-color: #135e9e;
  height: 40px;
  line-height: 40px;
  padding: 0 60px;
  position: relative;
  border-radius: 30px;
  transition: all .4s ease-in-out;
}

.btn-default i {
  background-color: #fff;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 50px;
  color: #155799;
  position: absolute;
  top: 50%;
  transition: all .4s ease-in-out;
  left: 6px;
  transform: translateY(-50%);
}
.journey__time::before {
  content: "Journey time";
  margin-right: 0.8rem;
  color: #7c8a97;
}

.find__car-btn {
  background: #000 !important;
  color: #fff !important;
  padding: 8px 15px;
  width: 100%;
  border: none;
  outline: none;
}

@media only screen and (max-width: 992px) {
  .find__cars-left {
    padding: 65px 22px;
  }

  .find__cars-left h2 {
    font-size: 1.5rem;
  }

  .form {
    padding: 30px 10px;
  }

  .form__group input,
  .select__group select {
    padding: 7px 10px;
    font-size: 0.8rem;
  }

  .hero__form {
    bottom: -100px;
  }
}

@media only screen and (max-width: 768px) {
  .find__cars-left {
    padding-top: 20px;
    padding-bottom: 0;
    background: #fff;
    text-align: center;
  }
  .newsletter-section .newsletter-sec {
    background: linear-gradient(90deg,#fdc830,#f37335);
    background-repeat: no-repeat;
    padding: 20px;
    border-radius: 10px;
    background-position: 0;
    margin-bottom: -185px;
    position: relative;
    overflow: hidden;
}
.newsletter-section .newsletter-sec .newsz-ltr-text>h2 {
  color: #2b2b2b;
  font-size: 16.58px;
  line-height: 1.7;
  margin-bottom: 33px;
  font-weight: 700;
}
.newsletter-section .newsletter-sec .newsletter-form {
  padding-left: 0px;
}
  .form {
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 576px) {
  .form__group {
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
  }

  .form__group input {
    font-size: 0.7rem !important;
  }

  .select__group {
    width: 100%;
    padding-right: 10px;
    font-size: 0.7rem;
  }

  .find__car-btn {
    font-size: 0.8rem;
  }

  .find__cars-left h2 {
    font-size: 1rem;
  }

  .hero__form {
    bottom: -250px;
  }
}
