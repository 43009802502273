.alert_banner{
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000ba;
}
.banner_outer{
width: 450px;
height:450px;
background: url(../assets/images/background/Group-new.png);
background-repeat: no-repeat;
background-size: 100%  100%;
background-position: center;
border-radius: 10px;
/* overflow: hidden; */
border:2px solid #f1c210;
position: relative;
}
.banner_outer::after{
    content: "";
    width: 200px;
    height:200px;
    background: url(../assets/images/background/abcd.png);
    background-repeat: no-repeat;
    background-size: 100%  100%;
    background-position: center;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    left:-25px;
    bottom: 0;
}
.banner_outer .session_year{
    
    position: absolute;
    right:20px;
    top:40px;
    
}
.session_year h2{
    font-weight: 900;
    font-size: 30px;
    font-family: "Archivo Black", serif;
    color:#3740ff;
}
.banner_outer .contact{
    position: absolute;
    right:8px;
    bottom:0px;
    color: #3740ff;
    font-size: 17px;
    font-family: "Archivo Black", serif;
}
.banner_outer .bannerlogo{
width: 90px;
height:90px;
padding: 5px;
}
.cancel_icon{
    color:red;
    position: absolute;
    top:5px;
    right:10px;
    z-index: 99;
}
.cancel_icon::after{
    content: "";
    background: #f1c210;
    opacity: 0.2;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    position: absolute;
    right: -5px;
    top: -2px;
    z-index: -1;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
}
.banner_points.admission_b{
  padding: 22% 0 0 15px;
  zoom: 0.18;
}
.banner_points {
    --i: var(--wide, 1);
    --ci: calc(1 - var(--i));
    filter: drop-shadow(0 1em 1em rgba(0, 0, 0, 0.2));
  
}

@media (max-width: 775px) {
  .banner_points {
    --wide: 0;
  }
}

@media (max-width: 250px) {
  .banner_points {
    font-size: 0.75em;
  }
}

@media (max-width: 185px) {
  .banner_points {
    font-size: 0.5em;
  }
}
.banner_points.admission_b article {
  width: 40%;
}
.banner_points article {
  --d: calc(var(--i)*4em + var(--ci)*1.375*1em);
  box-sizing: border-box;
  display: grid;
  grid-gap: 0px calc(var(--i)*5em);
  grid-template: repeat(2, max-content)/var(--d) 1fr;
  position: relative;
  margin: 1em 0;
  border: solid 0.5em transparent;
  padding: 0.125em;
  /* width: calc(var(--i)*32em + var(--ci)*100%); */
 
  min-height: 5em;
  border-radius: calc(var(--i)*2.5em);
  background: radial-gradient(circle at 0 10em, rgba(0, 0, 0, calc(var(--i)*.8)) 2.5em, transparent 11.25em) border-box, linear-gradient(#dbdbdb, #fff) content-box, linear-gradient(var(--slist)) padding-box, linear-gradient(#fff, #dcdcdc) border-box;
  font: 1.5em/1.375 trebuchet ms, verdana, sans-serif;
  clip-path: var(--wide, inset(0 round 2.5em));
  counter-increment: c;
}

.banner_points article:before {
  position: absolute;
  top: -0.5em;
  left: -0.5em;
  width: 9.5em;
  height: calc(var(--i)*5em);
  transform-origin: 100% 100%;
  transform: skewx(45deg);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  background: linear-gradient(#fff, #e4e4e4);
  content: var(--wide, "");
}

.banner_points article:after {
  display: grid;
  place-content: center;
  grid-row: 1/span calc(1 + var(--i));
  position: relative;
  border: solid calc(var(--i)*0.5em) transparent;
  margin: calc(var(--i)*-0.125em + var(--ci)*.25rem);
  width: var(--d);
  height: var(--d);
  border-radius: 50%;
  box-shadow: inset 0 0 1px 1px #efefef, inset 0 -0.5em rgba(0, 0, 0, 0.1);
  background: linear-gradient(var(--slist)) padding-box, linear-gradient(#d0d0d0, #e7e7e7) border-box;
  color: #fff;
  text-align: center;
  content: counter(c, decimal-leading-zero);
}

.banner_points h3,
.banner_points p {
  padding: 0 0.5rem;
}

.banner_points h3 {
    font-size: 2.05em;
    line-height: 1.7;
    color: #3740ff;
}

.banner_points p {
  /* grid-column: var(--ci)/span calc(1 + var(--ci)); */
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-right: 20px !important;
}

.btn-whatsapp-pulse {
    cursor: pointer;
    zoom: 0.6;
	background: #25d366;
	color: white;
	position: absolute;
	bottom: 20px;
	right: 20px;
	font-size: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0;
	height: 0;
	padding: 35px;
	text-decoration: none;
	border-radius: 50%;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}

.btn-whatsapp-pulse-border {
    bottom: 200px;
    right: 140px;
	animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
	content: "";
	position: absolute;
	border-radius: 50%;
	padding: 25px;
	border: 5px solid #25d366;
	opacity: 0.75;
	animation-name: pulse-border;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse-border {
	0% {
		padding: 25px;
		opacity: 0.75;
	}
	75% {
		padding: 50px;
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
