 #section-counter{
    padding:150px 0px;
 }      
.videowrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%)
  }
  
  .video-main {
    position: relative;
    display: inline-block;
  }
  
  .video {
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 100%;
    background: transparent;
    color: #fff;
    display: inline-block;
    background: #f6e057;
    z-index: 999;
  }
  
  @keyframes waves {
    0% {
      -webkit-transform: scale(0.2, 0.2);
      transform: scale(0.2, 0.2);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
      opacity: 0.9;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
      -webkit-transform: scale(0.9, 0.9);
      transform: scale(0.9, 0.9);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
  }
  
  .fa-play:before {
    content: "\f04b";
  }
  
  .waves {
    position: absolute;
    width: 150px;
    height: 150px;
    background: #f6e1575b;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border-radius: 100%;
    right: -50px;
    bottom: -50px;
    z-index: -1;
     -webkit-animation: waves 3s ease-in-out infinite;
    animation: waves 3s ease-in-out infinite;
  }
          
  .wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  
  .wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  
  .wave-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }