.common__section {
  background: linear-gradient(rgba(0, 0, 0, 0.883), rgba(0, 0, 0, 0.107)),
    url("../assets/images/pattern/pattern.jpg");
  background: url(../assets/images/pattern/pattern.jpg);
  background-position: center;
  background-size: contain;
  background-repeat: repeat-x;
  padding: 250px 0px 150px 0px;
  position: relative;
}

/* cloud start */
.common__section:before {
  height: 120px;
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  background-image: url(../assets/images/bg-cloud2.png);
  z-index: 1;
  left: 0;
  -webkit-animation: 65s scrollup infinite linear;
  -moz-animation: 65s scrollup infinite linear;
  -o-animation: 65s scrollup infinite linear;
  -ms-animation: 65s scrollup infinite linear;
  animation: 65s scrollup infinite linear;
  background-repeat: repeat-x;
}




.common__section h1 {
  /* color: #063862 !important; */
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 700;
  background-image: linear-gradient(to right, transparent, rgb(255, 255, 255), transparent);
  padding: 3px 60px !important;
  filter: drop-shadow(-3px -3px 2px rgba(255, 255, 255, .3)) drop-shadow(5px 5px 5px rgba(0, 0, 0, .2)) drop-shadow(5px 5px 5px rgba(0, 0, 0, .2));
}