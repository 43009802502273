.header__top {
  padding: 0px 0px;
  /* height:40px; */
  /* background: #66b845;
  background: linear-gradient(90deg,#a6dc6d, #a6dc6d, #5aa236 51%) var(--x, 0)/ 200%; */
  /* background-image: linear-gradient( 117deg,  rgba(123,216,96,1) 39.2%, rgba(255,255,255,1) 156.2% ); */
  /* background-image: linear-gradient(230deg, #f1eeda, #c2ac5e) !important; */
  color: #fff;
  align-items: center;
  display: flex;
  border-top: 0px solid #3c3c3c;
  background: transparent;
  background-color: rgba(250,250,250,0.15);
  background-color: #192f59;
}

.header__top__left {
  display: flex;
  align-items: center;
  padding:10px 20px;

  width:60%;
  /* background: linear-gradient(90deg,#a6dc6d, #76b84a, #76b84a 51%) var(--x, 0)/ 200%; */

}
.header{
  position: absolute;
  top:0;
  left:0;
  width:100vw;
  z-index: 99;
  /* box-shadow: 0 4px 5px 0 rgb(0 0 0 / 0%), 0 1px 10px 0 rgb(0 0 0 / 0%), 0 2px 4px -1px rgb(0 0 0 / 20%); */


}
.header__top__left span {
  font-size: 0.78rem;
  color:#fff;
  font-weight: 400 !important;
  letter-spacing: 0.5px;
}
.social__link-icon{
  background:#fff !important;
 border-radius: 5px;
 padding:2px 5px 0px 5px;
}
.header__top__help {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  /* font-weight: 600; */
  /* line-height: 2;
   */
}

.header__top__help i {
  background: #fff;
  color: #f80100;
  padding: 4px 8px;
  border-radius: 50%;
  font-size: 0.85rem;
  margin: 3px;
  display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #fff;
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
}
.menu_row{
  background-color: #192f59;
  margin: 10px auto;
  border-radius: 20px;
}
.header__top__right{
  padding:5px 20px;
  /* width:40%; */
  /* background: linear-gradient(90deg,#5aa236, #5aa236, #5aa236 51%) var(--x, 0)/ 200%; */

}
.header__top__right a {
  text-decoration: none;
  color:#f80100 !important;
  padding: 6px 8px !important;
  font-size: 0.9rem;
  border: 3px solid #fff;
  box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
}
.login_btn{
  padding: 2px 6px;
  border-radius: 4px;
  background: #fff;
}
.header__top__right a:hover {
  color: #fff;
}

/* ============ header middle style =========== */

.header__middle {
  padding: 10px 0px 0px 0px;
  display: flex;
  align-items: center;
  /* border-bottom: 3px solid #7bd860; */
}

.logo h1 a span {
  font-size: 1.5rem;
}

.logo h1 a i {
  font-size: 2.2rem;
}
/* .main__navbar.scrolled{
background: #3c3c3c !important;
} */
.logo h1 a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}

.logo h1 {
  line-height: 1.5rem;
}

.logo img{
  position: relative;
  max-width: 140px;
}
.header__location {
  height: 100%;
}
.header__location span i {
  font-size: 2.2rem;
  color: #12893a;
}

.header__location-content h4 {
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}

.header__location-content h6 {
  font-weight: 400;
  color:gray !important;
  font-size: 14px !important;
}

.header__btn {
  background-image: linear-gradient(230deg, #f1eeda, #c2ac5e) !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.363);
  padding: 10px 20px;
  border: none !important;
  
  outline: none;
  border-radius: 5px;
}

.header__btn a {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  text-decoration: none;
  color: #000;
  justify-content: end;
  font-size: 0.9rem;
}

.header__btn a:hover {
  color: #fff;
}
/* .header__top .col-md-5{
  background: white !important;
} */
/* ================ main navbar styles =========== */
.topbar{
  position: fixed;
  bottom: 0;
  width: 100%;
  left:0;
}
.main__navbars {
  zoom: 0.83;
  padding: 2px 0px;
  background: rgb(255, 255, 255);
  /* background: linear-gradient(0deg, rgba(1,9,9,0) 0%, rgba(255,255,255,1) 100%); */
  color:#1b4d89;
  /* border-top:2px solid #7bd860; */
}

.mobile__menu i {
  color: #000;
  font-size: 1.3rem;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 0.9rem;
}

.nav__item {
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 13.8px;
  font-weight: 500;
  padding:4px 10px 4px 10px;
  border-bottom: 2px solid transparent;
}

.divider {
  position: relative;
  margin-top: 30px;
  height: 1px;
}

.nav__item:hover {
  color: #fff;
}

.search__box {
  border: 1px solid #7c8a972f;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: #020a4de9;
}

.search__box input {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

.search__box input::placeholder {
  font-size: 0.8rem;
}
.search__box span i {
  color: rgba(255, 255, 255, 0.555);
  cursor: pointer;
}

.nav__active {
  color: #020a4de9 !important;
  background-image: linear-gradient(to right, transparent, #fff, transparent);
    padding: 5px 20px !important;
    filter: drop-shadow(-3px -3px 2px rgba(255, 255, 255, .3)) drop-shadow(5px 5px 5px rgba(0, 0, 0, .2)) drop-shadow(5px 5px 5px rgba(0, 0, 0, .2));
    font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .header__top,
  .header__middle,
  .nav__right {
    display: none;
  }

  .mobile__menu {
    display: block;
    padding: 20px;
}

  .navigation {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
    transition: all 1s ease-in-out !important;
  }
  .header {
    box-shadow: 0 4px 5px 0 rgb(0 0 0 / 0%), 0 1px 10px 0 rgb(0 0 0 / 0%), 0 2px 4px -1px rgb(0 0 0 / 20%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 99;
}
  .menu {
    width: 250px;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
    padding: 20px;
    justify-content: flex-start;
    transition: all 1s ease-in-out !important;
}
  .menu__active{
    transition: all 1s ease-in-out !important;
  }
  .menu a {
    color: #000;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .menu__active {
    display: block;
  }
}
.navigation__wrapper {
  padding: 10px 0px;
}
.header__top__left{
  position: relative;
 
}
.header__top__left a{
  color:#fff !important;
  font-size: 13px;
}
.header__top__left a:hover{
  color:#cfbf10 !important;
  font-size: 13px;
}
.dot {
  height: 6px;
  width: 6px;
  margin-left: 20px;
  margin-right: 10px;
  padding: 4px;
  margin-top: 2px !important;
  background-color: rgb(207, 23, 23);
  border-radius: 50%;
  display: inline-block;
  border: 2px solid #fff;
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
}
.header_social_icon li{
  margin:3px;
}

.header_social_icon{
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 0px;
}
.header__top__left:after { 
  position: absolute;
  right:20px;
  top:0;
  padding: 5px 0px;
  height:100%;
  width:0px;
    content: '';
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.014618347338935522) 79%, rgba(7, 7, 7, 0.47) 100%);
    
}
