.blog__item {
  border: 1px solid #7c8a972e;

  background-image: linear-gradient(210deg,var(--hmp-color-5) 0,var(--hmp-color-25) 100%);
background-color: #fff;
border-radius: 10px;
    box-shadow: 0 7px 13px -3px var(--hmp-color-25),0 2px 4px 0 var(--hmp-color-50),inset 0 0 0 0 var(--hmp-color);
}

.blog__time {
  border-top: 1px solid #7c8a972e;
}

.read__more {
  color: #f9a826;
  font-weight: 600;
  text-decoration: none;
  font-size: 1rem;
}

.read__more:hover {
  color: #f9a826;
}

.blog__time span i {
  color: #f9a826;
}

.blog__author {
  color: #000;
  font-weight: 600;
}

.blog__author i {
  font-weight: 500;
}

.blog__title {
  text-decoration: none;
  color: #000;
  font-size: 1.2rem;
  font-weight: 600;
  transition: 0.3s;
}

.blog__title:hover {
  color: #f9a826;
}

@media only screen and (max-width: 768px) {
  .blog__time {
    padding-top: 5px !important;
  }

  .blog__time span {
    font-size: 0.7rem;
  }

  .blog__info p {
    font-size: 0.7rem;
    line-height: 1.5rem;
    margin-top: 10px;
  }

  .blog__title {
    font-size: 1.1rem;
  }

  .read__more {
    font-size: 0.7rem;
  }
}
