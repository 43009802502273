
.footer__logo i {
  color: #fff;
  font-weight: 400;
}

.footer__logo span {
  color: #fff;
  font-weight: 500;
}

.footer__logo-content {
  color: rgba(255, 255, 255, 0.715);
  margin-top: 15px;
  line-height: 30px;
}

.quick__link {
  border: none !important;
  background: transparent !important;
}

.quick__link a {
  color: rgba(255, 255, 255, 0.715);
  text-decoration: none;
  font-weight: 300;
  transition: 0.3s;
}

.quick__link a:hover {
  color: #f6e057;
}

.footer__link-title {
  color: #fff;
}

.office__info {
  color: rgba(255, 255, 255, 0.715);
  font-weight: 300;
}

.newsletter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border: 1px solid #7c8a972f;
  border-radius: 50px;
  background: #020a4de9;
}

.newsletter input {
  background: transparent;
  color: #fff;
  border: none;
  outline: none;
}

.newsletter span i {
  color: #fff;
}

.footer__bottom {
  border-top: 1px solid #7c8a972f;
}

@media only screen and (max-width: 768px) {
  .footer__logo-content {
    font-size: 0.8rem;
  }

  .quick__link,
  .office__info {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

  .footer__link-title {
    font-size: 1rem;
    margin-top: 15px;
  }

  .footer__bottom p {
    font-size: 0.7rem;
  }

  
}
/* .footer_logo{
  filter: brightness(0) invert(1);
} */
.img_fluid{
  width:100%;
  margin:0 auto;
  text-align: center;
}
/* Footer Section css start */
.solar_footer {
  background-image: linear-gradient(to right, #192f59,#192f59);
  padding: 180px 0 0;
  /* margin-top: 100px; */
}
.solar_footer_ab{
  position: relative;
  height:100%;
}
.solar_footer_ab img{
  max-width: 115px;
}
.footer_school{
  position: absolute;
  opacity: 0.15;
  left:-40px;
  bottom: -20px;
  width:80%;
  height:180px;
  border-radius: 20px;
  z-index: -1;
}
.solar_footer_shape {
  background: linear-gradient(to bottom, #fff, #fff);
  padding: 40px 40px 20px;
  margin-top: -160px;
  z-index: 999;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 10px 10px 0px #0000001f;
  border: 3px solid #fff;
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
}
.solar_footer_shape::after{
  content: "";
  background: url(../assets/images/background/child.png);
  right:0;
  top:0;
  z-index: 99;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height:100%;
  background-position: right;
  background-size: contain;
}
.solar_footer_ab h5{
  letter-spacing: 0.5px;
}
.solar_footer_gradient {
  /* height: 60px; */
  /* background: linear-gradient(to right, #002a4c, #6abd45); */
  transform: rotate(0deg) skew(0deg, -2deg);
  width: 100%;
  position: absolute;
  top: -30px;
  left: 0;
}
.solar_footer_ab > p {
  color: #ffffff;
  margin: 40px 0;
}
.top_solar_btn > ul > li > a {
  margin: 0 15px 0 0;
  color: #063862;
  background-color: #fff;
  border-radius: 100px;
border: solid 1px #fff;
  display: inline-block;
  text-align: center;
-webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition:  all 0.3s ease-in-out;
  background: #fff;
    border-radius: 50%;
    padding: 8px 12px;
    border: 3px solid #fff;
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
}
.top_solar_btn > ul > li > a:hover {
  color: #ffffff;
box-shadow: 0 0 0 25px #002a4c inset;
-webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition:  all 0.3s ease-in-out;
}
.solar_footer_links {
  margin: 10px 0 0;
}
.solar_footer_links h3 {
  color: #063862;
  margin: 0 0 20px;
}
.solar_links > ul > li {
  border-bottom: solid 1px #ffffff2e !important;
  width: 100%;
  padding: 5px 0;
}
.solar_links > ul > li:last-child {
  border-bottom: none;
}
.solar_links > ul > li:first-child {
  padding: 0 0 5px;
}
.solar_links > ul > li > a {
  color: #063862;
  display: inline-block;
}
.solar_links > ul > li > i {
  margin: 0 10px 0 0;
color: #063862;
}
.solar_footer_contact {
  margin: 10px 0 0;
}
.solar_footer_contact h3 {
  color: #063862;
  margin: 0 0 20px;
  font-size: 18px;
  font-weight: 600;
}
.solar_information > ul > li {
  color: #063862;
  margin-bottom: 25px;
}
footer li{
  margin: 0 0 10px 0 !important;
}
.solar_footer_ab{
  text-align: center;
}
.solar_information > ul > li > i {
 margin-bottom: 5px;
  font-size: 20px;
    font-weight: 600;
    background: #fff;
    border-radius: 50%;
    padding: 8px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    border: 3px solid #fff;
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
}
.solar_footer_contact > p {
  color: #063862;
  margin: 20px 0 0;
  font-size: 20px;
}
.solar_footer_contact > h1 {
  color: #063862;
}
.solar_copyright {
  text-align: center;
  padding: 15px 0;
}
.solar_copyright p {
  color: #000;
}
.top_solar_btn > ul > li {
  display: inline-block;
}
/* Footer Section css end */
.center {
  width: 95%;
  margin:0 auto;
  display: flex;
  justify-content: center;
}
form {
  height: max-content;
  font-size: 14px;
  border: 1px solid #063862;
  border-radius: 50px;
  overflow: hidden;
}
input {
  font-size: inherit;
  width: 100%;
  padding: 5px 10px;
  margin-right: 0;
  transition: padding 0.2s;
  border: 0;
}
input::selection {
  background: #f6e057;
}
button {
  font-size: inherit;
  float: right;
  padding: 8px 20px;
  margin-left: -1px;
  background: #f6e057;
  transition: padding 0.2s;
  border: 0;
  text-wrap: nowrap;
}
.text_warning{
  color:#3472ee;
  border-bottom: 1px solid #3472ee;
}