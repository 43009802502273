.become__driver {
  background: linear-gradient(rgb(0, 13, 107, 0.9), rgb(0, 13, 107, 0.9)),
    url("../assets/all-images/drive.jpg");
    background: linear-gradient(to right, #002a4c, #002a4c);
   background: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0px 40px 0px;
}

.become__driver button {
  background: #fff;
  color: #000;
  font-weight: 600;
}
.dirc_img{
  width:70%;
  height:300px;
  border-radius: 10px;
  margin: 0 auto;
  box-shadow: 0 5px 20px -5px rgb(0 0 0 / 50%) !important;
}
.become__driver-title {
  font-size: 2rem;
  color: #192f59;
  line-height: 60px;
}
.watermark{
  position: absolute;
  width:200px;
  height:180px;
  right:5%;
  top:15%;
  opacity: 0.1;
}
@media only screen and (max-width: 992px) {
  .become__driver-title {
    font-size: 1.5rem;
    line-height: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .become__driver {
    padding: 50px 0px;
  }

  .become__driver-title {
    font-size: 1.3rem;
    line-height: 2.5rem;
  }

  .become__driver-btn {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 576px) {
  .become__driver-img {
    display: none;
  }
  .become__driver {
    text-align: center;
  }
}
