.blog__publisher span i {
  color: #f9a826;
}

blockquote {
  line-height: 35px;
}

.single__comment img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #000;
  object-fit: cover;
}

.replay i {
  color: #f9a826;
}

.replay {
  color: #000;
  font-weight: 600;
  cursor: pointer;
}

.leave__comment-form input,
.leave__comment-form textarea {
  border: 1px solid rgba(0, 12, 107, 0.118);
}

.leave__comment-form textarea:focus {
  outline: none;
}

.comment__btn {
  background: #000 !important;
  color: #fff !important;
}

.recent__blog-item h6 a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .recent__post {
    margin-top: 30px;
  }

  .leave__comment-form input,
  .leave__comment-form textarea {
    font-size: 0.7rem;
  }

  .comment__btn {
    padding: 5px 10px;
    font-size: 0.8rem;
    margin-top: 0px !important;
  }

  .leave__comment-form h4 {
    font-size: 1.2rem;
  }

  .single__comment img {
    width: 40px;
    height: 40px;
  }

  .comment__content p {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }

  .blog__details p {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }
}


.react_lightgallery_item img{
  width: 100%;
  height: 100%;
  transition: all 1s;
  background: #fff;
  border: 3px solid #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 5px 20px -5px rgb(0 0 0 / 50%) !important;
}

.react_lightgallery_item{
  border-radius: 6px;
  width: 100%;
  height: 200px;
  display: block;
  overflow: hidden;
  box-shadow: 0 5px 20px -5px rgb(0 0 0 / 50%) !important;
  margin-bottom: 2.2rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #fff;
}

.react_lightgallery_item:hover img{
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.react_lightgallery_item:hover {
  overflow: hidden !important;
  cursor: pointer;
}
.react_lightgallery_item{
  position: relative;
}
.react_lightgallery_item:hover:before {
  z-index: 0;
  background-color: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(66%, #00000000), color-stop(97%, #061733));
  background-image: linear-gradient(180deg, #18181800 6%, #061733 97%);
}
.react_lightgallery_item:hover:before {
  content: '\f065';
  font-family: "Font Awesome 5 Free";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: rgb(255, 255, 255);
  font-weight: 900;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(27, 26, 26, .25);
}