.about__section-item {
  column-gap: 3rem;
}

.about__section-item p i {
  color: #f9a826;
  font-size: 1.1rem;
}

@media only screen and (max-width: 992px) {
  .about__section-content h2 {
    font-size: 1.5rem;
  }
  .about__section-item {
    column-gap: 3rem;
  }
  .about__section-item p {
    font-size: 0.6rem;
    margin-bottom: 0;
  }

  .section__description i {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .about__img {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .about__section {
    margin-top: 0px !important;
  }
}

/* Checkmark List styling:*/
.sdsdsds ul {
  padding-left: 5px !important;
  list-style: none;
  margin: 1.5em 0 0 0;
}
.sdsdsds li {
  margin: 0.5em 0;
}
.sdsdsds li::before {
  content: "";
  display: inline-block;
  color:#247c04;
  transform: rotate(45deg);
  height: 0.8em;
  width: 0.43em;
  border-bottom: 0.15em solid;
  border-right: 0.15em solid;
  margin-right: 1em;
}
