
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Poppins:wght@300;400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins",sans-serif!important;
  font-weight: 400;
  overflow-x: hidden!important;
  font-family: Nunito,Verdana, Geneva, sans-serif !important;
}
html{
  overflow-x: hidden!important;
}
.MuiTab-textColorPrimary{
  color:#7c8a97 !important;
}
a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  text-decoration: none !important;
}
.main__navbar.scrolled .logo img {
  position: relative;
  top: 0px !important;
  max-width: 80px !important;
}
/* ========= pre-define css ========= */
h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1.1;
  color: #4d4d4d;
  text-transform: capitalize;
  margin-bottom: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
body {
  font-family: 'lato', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333333;
  font-weight: 400;
  overflow-x: hidden;
 
  -webkit-font-smoothing: antialiased;
  /* background-image: url(../src/assets/all-images/teacher-bg.jpg);
  background-size: contain;
  background-repeat: repeat-y;
  background-position: center; */
  background: #fff !important;
}
h3,
h4 {
  font-size: 1.6rem;
}

h5,
h6 {
  font-size: 1.2rem;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}

ul {
  margin: 0;
  list-style: none;
}

.section__title {
  color: #020a43;
  font-weight: 600;
  font-size: 2rem;
}

.section__subtitle {
  color: #f80100;
  font-weight: 600;
  font-size: 1.2rem;
}
.dfdf_topper{
  background: url(../src/assets/all-images/teacher-bg.jpg);
  background-position: center;
background-size: cover;
}
.banner-section {
  width: 100%;
}
.banner-section3{
  position: relative;
}
.banner-section3::before {
  content: "";
  position: absolute;
  top:0;
  left:-150px;
  z-index: 0;
  background-image: url(../src/assets/images/background/sm-rainbow.png);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height:100%;
  opacity: 1;
  transform: scaleX(-0.7);
  animation: smoothFadeIn 8s ease-in-out infinite; /* Smooth fade-in and fade-out */
}
@keyframes smoothFadeIn {
  0% {
    opacity: 1; /* Completely hidden */
  }
  10% {
    opacity: 0.5; /* Gradually starting to show */
  }
  30% {
    opacity: 0.3; /* Fully visible */
  }
  50% {
    opacity: 0; /* Gradually starting to hide */
  }
  70% {
    opacity: 0.3; /* Gradually starting to hide */
  }
  90% {
    opacity: 0.5; /* Gradually starting to hide */
  }
  100% {
    opacity: 1; /* Completely hidden */
  }
}
.banner-section3::after {
  content: "";
  position: absolute;
  top: -50px;
  right: 0;
  z-index: 2;
  background-image: url(../src/assets/images/background/sm-rainbow.png);
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0; /* Initial state hidden */
  animation: smoothFadeInOut 8s ease-in-out infinite; /* Smooth fade-in and fade-out */
}
@keyframes smoothFadeInOut {
  0% {
    opacity: 0; /* Completely hidden */
  }
  10% {
    opacity: 0.1; /* Gradually starting to show */
  }
  50% {
    opacity: 1; /* Fully visible */
  }
  90% {
    opacity: 0.1; /* Gradually starting to hide */
  }
  100% {
    opacity: 0; /* Completely hidden */
  }
}
.banner-section2 {
  position: relative;
}
.banner-section2{
  background-image: url(../src/assets/all-images/header2.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height:480px;
  
}
.side_section2 {
  position: absolute;
  left: 150px;
  bottom: -15px;
  width: 400px;
  z-index: 9;
}
.section__description {
  color: #7c8a97;
  font-size: 0.9rem;
  line-height: 1.5;
}

section {
  padding: 50px 0px;
}

.btn {
  padding: 7px 15px;
  background: #000;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  transition: 0.3s;
}

.btn:hover {
  color: #fff !important;
  background: #000c6bd0;
}

@media only screen and (max-width: 768px) {
  .main__navbar.scrolled .logo img {
    position: relative;
    top: 0px !important;
    max-width: 70px !important;
}
  .main_gallery .col-md-3 {
    width: 50% !important;
    padding: 5px !important;
}
.react_lightgallery_item {
  margin-bottom: 5px !important;
  min-height: 130px !important;
  height: 130px !important;
}
  .solar_copyright.d-flex.justify-content-between{
    display: block !important;
  }
  .about_rotate .col-md-6{
    width:50% !important;
  }
  h1.text_span {
    text-align: left;
    color: #020a43;
    font-size: 20px;
    font-weight: 800;
}
  .common__section {
    background: linear-gradient(rgba(24, 24, 24, 0.6), rgba(30, 30, 30, 0.6)), url(./assets/all-images/events-details3.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px 0px !important;
    margin-top: 80px !important;
}
  .hjdcjdc {
    position: relative;
    margin-top: 200px;
}
.tab_sec .MuiAppBar-root {
  width: 100% !important;
}
.tab_sec .MuiTab-root {
  padding: 6px 10px !important;
  overflow: hidden;
  position: relative;
  font-size: 1.2rem !important;
  
}
  .solar_btn {
    color: #ffffff;
    background: linear-gradient(90deg, #5aa236, #a6dc6d 51%, #5aa236) var(--x, 0)/ 200%;
    height: 30px !important;
    display: inline-block;
    padding: 0 25px !important;
    font-weight: 500;
    line-height: 1.7 !important;
    border-radius: 100px;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
}

  .logo img {
    position: relative;
    top: 0px !important;
    max-width: 70px !important;
}
.logo {
  position: absolute !important;
  width:60px !important;
  max-width: 80px;
}
  section {
    padding: 30px 0px;
  }

  .section__title {
    font-size: 1.6rem;
  }

  .section__subtitle {
    font-size: 1.1rem;
  }
}



/* news section start */
.headings {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 2em 0;
}
.headings h2{
  font-size: 1.5rem;
  font-family: Merriweather;
  font-weight: 900 !important;
}
.title {
  margin: 0;
}
.hr {
  display: block;
  flex: 1;
  margin: 0 30px;
  height: 3px;
  background: #D4D4D4;
}
.hr_short {
  display: block;
  flex: 0.5;
  margin: 0 30px;
  height: 3px;
  background: #D4D4D4;
}
.news_sec .carousel .slide {
  min-width: 100%;
  margin: 0;
  height: 200px !important;
  position: relative;
  text-align: center;
}
.news_sec .carousel .slider-wrapper{
  height: 450px !important;
}
.new_carousel_main .carousel:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 250px !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDA0Nzg2OTJDOUJDMTFFNEJCODlEQTMyNDgyMUVFMDMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDA0Nzg2OTNDOUJDMTFFNEJCODlEQTMyNDgyMUVFMDMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEMDQ3ODY5MEM5QkMxMUU0QkI4OURBMzI0ODIxRUUwMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEMDQ3ODY5MUM5QkMxMUU0QkI4OURBMzI0ODIxRUUwMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuEoCmAAAAAqSURBVHjaYvj//z/DjnXroqD0ZhQOiGZA5oAkGcHKGBj8gDgSiJcDBBgAytIlpFxRipgAAAAASUVORK5CYII=) #000000;
  opacity: 0.3;
}
.news_sec .carousel .slide img{
  width:100%;
  height:250px !important;
}
.news_sec p{
  text-align: justify;
}
.news_sec h5{
  font-weight: 800 !important;
  color:#030449;
}

.showcase {
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  text-align: center;
}

.showcase img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  z-index:99
}

.showcase .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 36, 82, 0);
  position: absolute;
  top: 0;
  left: 0;
  z-index:999
}

.showcase h2 {
  margin-top: 30px;
  font-size: 1.5em;
  font-weight: 800;
  color:#febe00;
}

.showcase p {
  margin-top: 8px;
  font-size: 1.2em;
  color:white;
}
.quick_link{
  margin-top: 40px;
  padding:20px;
  list-style-type: none;
}
.quick_link li{
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: Merriweather;
  padding:9px 5px;
  border-bottom: 1px solid #febe00;
  transition: all 1s;
}
.quick_link li:hover{
  color:#febe00 !important;
  cursor: pointer;
  transform: scale(1.1);
}
.showcase .overlay:before{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.7;
}
.overlay:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDA0Nzg2OTJDOUJDMTFFNEJCODlEQTMyNDgyMUVFMDMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDA0Nzg2OTNDOUJDMTFFNEJCODlEQTMyNDgyMUVFMDMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEMDQ3ODY5MEM5QkMxMUU0QkI4OURBMzI0ODIxRUUwMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEMDQ3ODY5MUM5QkMxMUU0QkI4OURBMzI0ODIxRUUwMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuEoCmAAAAAqSURBVHjaYvj//z/DjnXroqD0ZhQOiGZA5oAkGcHKGBj8gDgSiJcDBBgAytIlpFxRipgAAAAASUVORK5CYII=) #000000;
  opacity: 0.7;
}
.overlay h2{
  text-align: center;
  width: 100%;
  position: absolute;
  z-index: 9;
}
.quick_link{
  width: 100%;
  position: absolute;
  z-index: 9;
}
.quick_link li{
  margin: 0 auto;
}
/* news section close */

.solar_about_detail {
  position: relative;
}

.animation img {
  position: absolute;
  z-index: -1;
}
.tab_sec{
  background-color: transparent !important;
}
.circle__lg {
  left: -30%;
  bottom: 50%;
  max-width: 19vw;
  animation: multiply 15s infinite alternate-reverse;
}
.become__driver{
  position: relative;
}
.signature{
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
font-size: 1.6rem;
position: absolute;
right:50px;
bottom: 50px;
}
.sdfeg{
  width:100%;
}
.tab_sec .MuiAppBar-positionStatic {
  position: absolute !important;
  right: 0px;
  top: 0px;
  box-shadow: none !important;
  background-color: white !important;
}
/* .wrapper {
  position: absolute;
  left:-42px;
  width: 43px;
  height: 43px;
  margin: 0 auto;
  transform: rotate(180deg);
}
.wrapper.direc{
  left:-52px !important;
  width: 52px !important;
  height: 50px !important;
} */
.PrivateTabIndicator-colorPrimary-3 {
  background-color: #192f59 !important;
}
.top_angle{
  top:0px;
  left:-42.7px !important;
}
.top_angle.wrapper2{
  top:0px;
  left:53.75% !important;
 
}

/* .top_angle div{
  background: #a6dc6d !important;
} */
/* .top_angle.wrapper2 div{
  background: #5aa236 !important;
} */
.wrapper div {
  width: 100%;
  height: 100%;
  background: white;
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
  clip-path: polygon(0 0, 0 100%, 100% 100%);
}
.tab_sec .MuiAppBar-root{
  width:57%;
}
@media (min-width: 600px){
.MuiTab-root {
    min-width: 50% !important;
}
}
.tab_sec .MuiTab-root {
  padding: 6px 12px;
  overflow: hidden;
  position: relative;
  font-size: 1.2rem;
  max-width: 264px;
  min-width: 72px;
  box-sizing: border-box;
  min-height: 48px;
  text-align: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  white-space: normal;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  border-radius: 20px !important;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  margin: 12px 10px;
}
@-webkit-keyframes multiply {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    opacity: 1;
  }
  50% {
    -webkit-transform: translate(30px, -30px) rotate(15deg);
            transform: translate(30px, -30px) rotate(15deg);
    opacity: 0.5;
  }
  75% {
    -webkit-transform: translate(0px, 0px) rotate(10deg);
            transform: translate(0px, 0px) rotate(10deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
    opacity: 1;
  }
}

@keyframes multiply {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    opacity: 1;
  }
  50% {
    -webkit-transform: translate(30px, -30px) rotate(15deg);
            transform: translate(30px, -30px) rotate(15deg);
    opacity: 0.5;
  }
  75% {
    -webkit-transform: translate(0px, 0px) rotate(10deg);
            transform: translate(0px, 0px) rotate(10deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
    opacity: 1;
  }
}
.facilities_blocks .row{
  display: flex;
  align-items: center;
  margin: 20px 0px;
}
.facilities_blocks .row img{
  min-height: 300px;
max-height: 300px;
border-radius: 15px;
box-shadow: 6px 11px 41px -28px #a99de7 !important;
}
.myClassName1{
  margin-top: 5px;
}
.main__navbar.scrolled {
  position: fixed;
  top:0;
  left: 0;
  z-index: 9999 !important;
  right: 0;
  border-radius: 0px !important;
  width:100% !important;
  margin: 0 auto !important;
  animation: scrolled 0.3s ease forwards;
  box-shadow: 0 4px 5px 0 rgb(0 0 0 / 0%), 0 1px 10px 0 rgb(0 0 0 / 0%), 0 2px 4px -1px rgb(0 0 0 / 20%);
}
.dfdf{
  /* background: url(../src/assets/images/background/section.png);
  background-position: center;
  background-size: contain;
  background-repeat: repeat-x; */
  position: relative;
}

.dfdf::before{
  content: "";
  background: url(../src/assets/images/background/right-fit.webp);
  width:50%;
  height:60%;
  top:30%;
  left:0;
  position: absolute;
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
}
.text_center{
  width:80%;
  margin: 0 auto !important;
  text-align: center !important;
}
h2.text_span {
  font-family: 'Crimson Pro', serif !important;
  font-size: 35px !important;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 2px;
  margin-bottom: 20px;
  text-transform: capitalize;
  background: #5E0914;
  -webkit-background-clip: text !important;
  color: transparent !important;
  text-shadow: rgb(255 255 255 / 50%) 0px 3px 3px !important;
  filter: drop-shadow(-3px -3px 2px rgba(255, 255, 255, .3)) drop-shadow(5px 5px 5px rgba(0, 0, 0, .2));
}
.divider {
  position: relative;
  margin-top: 30px;
  height: 1px;
}
.div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  right: 0%;
  width: 90%;
  height: 0.5px;
  background-image: linear-gradient(to right, transparent, rgba(247, 245, 245, 0.735), transparent);
}
.section-title {
  font-size: 30px;
  color: navy;
  display: inline-block;
  font-weight: 700;
  position: relative;
  margin-bottom: 55px;
}
.gap_4{
  position: relative;
}
.gap_4::after{
  display: none;
  content:"";
  width: 100%;
  height:300px;
  background-repeat: no-repeat;
  background: url(../src/assets/images/background/event.png);
    position: absolute;
    top: 0;
    background-size: contain;
    background-repeat: no-repeat;
}
 .events_headings{
  font-size: 0.85rem;
  font-weight: 600;
  width: 75%;
  text-align: center;
  margin: 0 auto;
  line-height: 1.5;
  margin-top: 10px;
 }
.kefe{
  color:#020a43;
}
.date_tag{
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 9;
    /* border-radius: 0px 0px 6px 6px;
    display: inline-block;
    background: #ffc600;
    background-image: linear-gradient(230deg, #ffc480, #ff763b);
    background-image: linear-gradient(230deg, #f1eeda, #c2ac5e); */
    color: #020a43;
    font-weight: 600;
    padding: 4px 8px;
    letter-spacing: 2px;
    font-size: 12px;
    font-weight: 600;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
z-index: 999;
}
.sdsdsds{
  padding-bottom: 80px;
}
/* 
.date_tag:before {
  width: 0;
  right: -8px;
  height: 0;
  border-style: solid;
  border-width: 8px 0 0 8px;
  border-color: transparent transparent transparent #c2a85e;
}
.date_tag:after, .date_tag:before {
  position: absolute;
  top: 0;
  content: '';
}
.date_tag:after {
  left: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 8px 8px;
  border-color: transparent transparent #c2a85e transparent;
} */

.set_new_height .quick_link li {
  margin: 0 auto;
  text-align: left;
}
.set_new_height .quick_link li:hover{
  color:#febe00 !important;
  cursor: pointer;
  transform: none !important;
}
.set_new_height .quick_link li a:before {
  display: inline-block;
  font-family: FontAwesome;
  content: '\f1c1';
  padding-right: 1rem;
  color:rgb(205, 48, 48);
  font-size: 20px;
  font-weight: 300 !important;
}
.set_new_height .quick_link li a:after {
  display: inline-block;
  font-family: FontAwesome;
  content: '\f019';
  padding-right: 1rem;
  color:rgb(226, 167, 66);
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  right:10px;
}
.set_new_height{
  min-height:140vh;
  max-height:150vh;
}
.set_new_height .showcase .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(64, 64, 65, 0.067);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.mb_30 .common__section.mb-5{
  margin-bottom: 0px !important;
}
.set_new_height .quick_link a{
  color:#fff;
}
/* flaticons start */


	/*
  	Flaticon icon font: Flaticon
  	Creation date: 06/10/2018 08:32
  	*/


    @font-face {
      font-family: "Flaticon";
      src: url("./fonts/flaticon/font/Flaticon.eot");
      src: url("./fonts/flaticon/font/Flaticon.eot?#iefix") format("embedded-opentype"),
           url("./fonts/flaticon/font/Flaticon.woff") format("woff"),
           url("./fonts/flaticon/font/Flaticon.ttf") format("truetype"),
           url("./fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
      font-weight: normal;
      font-style: normal;
    }
    
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      @font-face {
        font-family: "Flaticon";
        src: url("./fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
      }
    }
    
    [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
    [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
      font-family: Flaticon;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    
    .flaticon-teacher:before { content: "\f100"; }
    .flaticon-books:before { content: "\f101"; }
    .flaticon-diploma:before { content: "\f102"; }
    .flaticon-reading:before { content: "\f103"; }
    .flaticon-security:before { content: "\f104"; }
    .flaticon-education:before { content: "\f105"; }
    .flaticon-jigsaw:before { content: "\f106"; }
    .flaticon-kids:before { content: "\f107"; }

    .hero_banner{
      position: relative;
    }
    .slider_img{
      width: 100%;
      height: 100vh;
    }
    .slick-next{
      right:15px !important;
      z-index: 9999;
    }
    .slick-prev{
      left:15px !important;
      z-index: 9999;
    }
   .admin_gallery_img img{
    border:3px solid #fff;
    min-height:100px;
    max-height:180px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
    position: relative;
    margin-bottom: 20px;
   }
   .admin_gallery_img div{
    position: relative;
   }
   .admin_gallery_img button{
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
    position: absolute;
    top:-4.5px;
    right:3px;
    z-index: 9;
    font-size: 10px;
    
   }
   .admin_heading{
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color:red;
    margin-top: 100px;
   }
   .adj_sec{
    margin-top: 200px;
   }
   .hero__slider-section .slick-slide img {
    display: block;
    width: 100%;
    height: 100vh;
}
.mpd_p{
  text-transform: capitalize;
}
.curriculam .card {
  position: relative;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}
.curriculam .card:hover {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
}
.sett{
  max-height: 150px;
  overflow:hidden;
}
.curriculam .card a {
  color: inherit;
  text-decoration: none;
}

.curriculam .card__date {
  background: #08c;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px 15px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  line-height: 13px;
  font-weight: bold;
  padding-top: 10px;
}
.curriculam .card__date__day {
  display: block;
  font-size: 14px;
}
.curriculam .card__date__month {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
}

.curriculam .card__thumb {
  height: 235px;
  overflow: hidden;
  background-color: #000;
  transition: height 0.3s;
}
.curriculam .card__thumb img {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s, transform 0.3s;
  /* transform: scale(1); */
}
thead tr th{
  white-space: nowrap;
}
.curriculam .card__body {
  position: relative;
  padding: 20px;
  height: 285px;
  transition: height 0.3s;
}

.curriculam .card__category {
  position: absolute;
  top: -25px;
  left: 0;
  height: 25px;
  padding: 0 15px;
  background: #08c;
  color: #fff;
  font-size: 11px;
  line-height: 25px;
}
.curriculam .card__category a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}

.curriculam .card__title {
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 22px;
  color: #000;
  font-weight: bold;
}

.curriculam .card__subtitle {
  margin: 0;
  padding: 0 0 10px 0;
  color: #08c;
  font-size: 19px;
}

.curriculam .card__description {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 65px;
  margin: 0;
  padding: 0;
  color: #666c74;
  font-size: 14px;
  line-height: 27px;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  transition-delay: 0s;
  transform: translateY(25px);
}

.curriculam .card__footer {
  position: absolute;
  color: #a3a9ab;
  bottom: 20px;
  left: 10px;
  right: 10px;
  font-size: 11px;
  display: flex;
  justify-content: space-around;
}
.curriculam .card__footer span {
  text-align: center;
  padding: 3px 12px !important;
  font-size: 13px !important;
  min-width: 100px !important;
  line-height: 1.3;
}

.curriculam .icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
}
.curriculam .icon:before {
  display: inline-block;
  text-align: center;
  height: 14px;
  width: 14px;
  margin-top: -2px;
  margin-right: 6px;
}

.curriculam .icon--comment:before {
  content: "";
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.curriculam .icon--time:before {
  content: "";
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

@-webkit-keyframes titleBlur {
  0% {
    opacity: 0.6;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.6);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0);
  }
}

@keyframes titleBlur {
  0% {
    opacity: 0.6;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.6);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0);
  }
}

@-webkit-keyframes subtitleBlur {
  0% {
    opacity: 0.6;
    text-shadow: 0px 5px 5px rgba(0, 136, 204, 0.6);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 5px 5px rgba(0, 136, 204, 0);
  }
}
@keyframes subtitleBlur {
  0% {
    opacity: 0.6;
    text-shadow: 0px 5px 5px rgba(0, 136, 204, 0.6);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 5px 5px rgba(0, 136, 204, 0);
  }
}


.about_us_image{
  width:100%;
  min-height:350px;
  position: relative;
  background: #fff;
  border-radius: 12px;
}
.about_us_image img{
  width: 100%;
  max-height:400px;
}
.banner-section{
  position: relative;
}
.side_section{
  position: absolute;
  right:150px;
  bottom:-10px;
  width:400px;
  z-index: 9;
}

.img_culture{
  /* padding-top: 25px; */
  border-radius: 8px;
  max-height:270px;
  width: 85%;
  margin:0 auto;
  border: 3px solid #fff;
  box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
}
.testimonial.main{
  background: #fff;
  border-radius: 10px;
  border: 3px solid #fff;
  box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
}
.testimonial_col{
  position: relative;
}
.quote_right{
  position: absolute;
  transform: rotate(-180deg);
  top:0;
  left:-50px;
  z-index: 99;
}
.quote_left{
  position: absolute;
  top:0;
  right:-50px;
  z-index: 99;
}
.downloads_btn{
  text-align: center;
  border:2px solid #020a43;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:6px 20px;
  border-radius: 6px;
  background: #fff;
  filter: drop-shadow(-3px -3px 2px rgba(255, 255, 255, .3)) drop-shadow(5px 5px 5px rgba(0, 0, 0, .2)) drop-shadow(5px 5px 5px rgba(0, 0, 0, .2));
  margin: 15px 0px;
}
.downloads_btn i{
  border: 2px solid #fff;
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
    border-radius: 6px;
    padding: 4px;
}
.downloads_btn:hover{
  filter: drop-shadow(none) !important;
  cursor: pointer;
}
.downloads_btn img{
  width:40px !important;
}
/* #main_news_layout{
  background: url(./assets/all-images/teacher-bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;

} */
#main_news_layout{
  background: url(./assets/images/background/clouds.jpg) no-repeat;
  background-position: center;
  background-size: 100% 100%;

}
.mb00{
  margin-bottom: 0px !important;
}
.feestr{
  background: url(./assets/all-images/fee-structure.jpg) !important;
}
.header_social_icon ul{
  display: flex;
  padding-left:0px !important;
  margin-bottom: 0px !important;
}
.header_social_icon ul li{
  padding:5px !important;
}
.calender{
  margin-top:240px;
  margin-bottom:50px;
  height:70vh;
}
.exam_notice{
  width: 100%;
  
}
.news-scroll{
  display: flex;
}
.exam_notice_main .col-6{
  background: #fff;
    border-radius: 12px;
    padding: 20px;
    border: 3px solid #fff;
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
}
.hero__slider-section{
  margin-top: 80px;
}
.header__top__left a span{
  color: red !important;
}

.director img{
  max-height: 300px;
  text-align: center;
  border: 3px solid #fff;
    box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 8%), 0 -0.3rem 0.6rem rgb(0 0 0 / 15%) inset;
    border-radius: 12px;
    padding: 3px;
}
.director h2{
  color: #000;
  font-size: 1.9rem;
}
.theme_button{
  position: absolute;
  width:100%;
  margin: 0 auto;
  top:150px;
  z-index: 9;
  color: #fff;
  transition: transform 0.5s ease-in-out;
 
}
.theme_button div{
  border-radius: 6px 0px 0px 6px;
  border:5px solid #fff;
  border: 1px solid #e6eaf2;
  box-shadow: 0 5px 25px #0000000a, 0 3.2px 12px #00000014;
}

.theme_button button{
  margin:5px;
}
.after_main {
  border-radius: 25px;
  background: #fff !important;
  min-height: 98vh;
  max-height: 98vh;
  width: 100%;
  overflow-y: auto;
}

/* .theme_button div{
  border: 1px solid #e6eaf2;
  box-shadow: 0 5px 25px #0000000a, 0 3.2px 12px #00000014;
  border-radius: 15px 0px 0px 15px;
} */
.not_visibl{
  visibility: hidden !important;
}
.colorimg {
  width: 30px;
  position: absolute;
  left: -30px;
  top: 10%;
  background: #fff;
  border: 1px solid #e6eaf2;
    box-shadow: 0 5px 25px #0000000a, 0 3.2px 12px #00000014;
  padding: 5px;
  height: 30px;
  border-radius: 20px 0px 0px 20px;
  margin-right: 5px;
}
.theme_button{
  display: none !important;
  }
#root:has(.adj_sec.Admin) .theme_button{
display: block !important;
}
.tabss{
  margin-top: 150px;
}
.map_admin_td tr td{
  text-overflow: ellipsis;
  text-wrap: wrap !important;
}
.map_admin_td textarea{
  width:600px;
}
.slide_cloud {
  -webkit-mask-image: url(../src/assets/images/main-slider/slide-bg.png);
  mask-image: url(../src/assets/images/main-slider/slide-bg.png);
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: auto 100%;
  mask-size: auto 100%;
}
.slide_cloud::before{
content: "";
position: absolute;
top:0;
left:0;
background: #0000005e;
width: 100%;
height:100%;
z-index: -1;
}
.slide_cloud .static_content{
  position: absolute;
  top: 25%;
  z-index: 9;
  left: 100px;
  filter: drop-shadow(0px -3px 2px rgba(255, 255, 255, .3)) drop-shadow(5px 5px 5px rgba(0, 0, 0, .2)) drop-shadow(5px 5px 5px rgba(0, 0, 0, .2));
}
.static_content h2{
    color: #fff;
    font-weight: 900;
    width: 60%;
    line-height: 1.5;
}
.static_content p{
  color: #fff;
  font-weight: 500 !important;
  width: 50%;
  line-height: 1.3 !important;
  font-size: 15px !important;
}
.slide-item {
  position: relative;
  text-align: center;
}

.zoom-out {
  width: 100%;
  height: auto;
  animation: zoomOut 3s ease-in-out infinite; /* 3s includes 2s delay and smooth transition */
  animation-delay: 2s;
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.8;
  }
}


.common__section:after {
  height: 100px;
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  background-image: url(../src/assets/images/bg-cloud1.png);
  z-index: 1;
  left: 0;
  -webkit-animation: 60s scroll infinite linear;
  -moz-animation: 60s scroll infinite linear;
  -o-animation: 60s scroll infinite linear;
  -ms-animation: 60s scroll infinite linear;
  animation: 60s scroll infinite linear;
  background-repeat: repeat-x;
}


@keyframes rainbowright{
	100%{
		background-position: -1000px 0px;
    opacity: 0.2;
	}
}

@-webkit-keyframes scroll{
	100%{
		background-position: -3000px 0px;
	}
}

@-moz-keyframes scroll{
	100%{
		background-position: -3000px 0px;
	}
}

@-o-keyframes scroll{
	100%{
		background-position: -3000px 0px;
	}
}

@-ms-keyframes scroll{
	100%{
		background-position: -3000px 0px;
	}
}

@keyframes scroll{
	100%{
		background-position: -3000px 0px;
	}
}
/* Image Move Css */
@-webkit-keyframes scrollup{
	100%{
		background-position: 3000px 0px;
	}
}

@-moz-keyframes scrollup{
	100%{
		background-position: 3000px 0px;
	}
}

@-o-keyframes scrollup{
	100%{
		background-position: 3000px 0px;
	}
}

@-ms-keyframes scrollup{
	100%{
		background-position: 3000px 0px;
	}
}

@keyframes scrollup{
	100%{
		background-position: 3000px 0px;
	}
}

.clouds {
  position: relative;
  display: flex;
  align-items: center;
  height: 540px;
}

.animation {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.animation::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #fff, #0000, #fff);
  z-index: 1;
}

.animation::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height:100%;
  background: #03a9f445;
}

.animation .cloud {
  background-repeat: repeat-X;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.animation .cloud1 {
  background-image: url('https://raw.githubusercontent.com/gestok/clouds/main/clouds/clouds_1.png');
  animation: move1 20s infinite linear;
}
.animation .cloud2 {
  background-image: url('https://raw.githubusercontent.com/gestok/clouds/main/clouds/clouds_2.png');
  animation: move1 15s infinite linear;
}
.animation .cloud3 {
  background-image: url('https://raw.githubusercontent.com/gestok/clouds/main/clouds/clouds_3.png');
  animation: move1 17s infinite linear;
}

.container {
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.clouds {
  text-align: center;
}
.clouds h2 {
  font-size: 40px;
  text-shadow: 0 1px 2px #15151550;
  margin: 0 0 12px;
}
.clouds p {
  max-width: 400px;
  margin: 6px auto 24px;
  text-shadow: 0 1px 2px #15151550;
}
.clouds .btn {
  text-shadow: 0 1px 2px #15151550;
  background: transparent;
  border: solid 1px #fff;
  padding: 10px 21px;
  color: inherit;
  font-weight: 700;
  font-size: 17px;
  cursor: pointer;
  box-shadow: 0 8px 16px -4px #15151550;
}
.clouds .btn:hover {
  background: #fff2;
}

.divider {
  position: relative;
  height: 28px;
}

@keyframes move1 {
  100% {background-position: -1000px 0;}
}
@keyframes move2 {
  100% {background-position: -1000px 0;}
}
@keyframes move3 {
  100% {background-position: -1579px 0;}
}
.main_layout{
  padding: 0px 50px;
}
.upnews span{
  color: dodgerblue !important;
}
.upnews h6{
  color: red !important;
}
.alert_banner:empty {
  display: none;
}
.tab-content{
  position: relative;
}
.calender{
  margin:180px 0px 100px 0px;
}

h1.modal-title{
  color:#000 !important;
}
.form-group:has(input:disabled) {
  display: none !important;
}
/* .slide-item:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../src/assets/images/background/overlay-pattern.png) #000000;
  opacity: 0.5;
} */
.footer{
  padding-bottom: 45px;
}
.form-group:has(input[value="youtubeclass"]) {
  display: none !important;
}



button, .solar_btn {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle !important;
  color: inherit;
  font: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  border-radius: 0;
  text-align: inherit;
  text-transform: inherit;
  -webkit-tap-highlight-color: transparent;
}

.custom__button {
  margin: 3px;
  cursor: pointer;
}

.custom__button.a, .solar_btn {
  border: 1px solid #0360df !important;
  border-radius: 50px !important;
  padding: 8px 16px !important;
  background-color: #0360df !important;
  background-image: radial-gradient(75% 50% at 50% 0%, #f4feff 12%, transparent), radial-gradient(75% 50% at 50% 85%, #8de3fc, transparent) !important;
  box-shadow: inset 0 0 2px 1px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(17, 110, 231, 0.2), 0 1px 4px 1px rgba(0, 0, 0, 0.1) !important;
  color: #fff !important;
  text-shadow: 0 1px 1px #116ee7;
  transition-property: border-color, transform, background-color;
  transition-duration: 0.2s;
}
.custom__button.a:hover {
  transform: scale(1.04);
}
.solar_btn{
  display: flex;
  align-items: center;
}

fieldset:has(input[name="contenturl"]) .form-group:has( input[type="checkbox"]) {
  display:none !important;
}
fieldset input[name="contenturl"][value="Top Section"],
fieldset input[name="contenturl"][value="Video Section"],
fieldset input[name="contenturl"][value="Gallery Section"],
fieldset input[name="contenturl"][value="Blog Section"]{
  display:none !important;
}
fieldset:has(input[name="contenturl"][value="Top Section"]) .form-group:has(input[name="startdate"]),
fieldset:has(input[name="contenturl"][value="Top Section"]) .form-group:has(input[name="enddate"]){
 display:none !important;
}

/* Hide specific fields based on the contenturl value */
fieldset:has(input[name="contenturl"][value="Gallery Section"]) .form-group,
fieldset:has(input[name="contenturl"][value="Video Section"]) .form-group {
  display: none !important;
}
fieldset:has(input[name="contenturl"][value="Video Section"]) .form-group:has(input[type="radio"]),
fieldset:has(input[name="contenturl"][value="Video Section"]) .form-group:has(input[name="content"]),
fieldset:has(input[name="contenturl"][value="Video Section"]) .form-group:has(input[name="imageurl"]) {
  display: block !important;
}
fieldset:has(input[name="contenturl"][value="Gallery Section"]) .form-group:has(input[type="radio"]),
fieldset:has(input[name="contenturl"][value="Gallery Section"]) .form-group:has(input[name="imageurl"]) {
  display: block !important;
}
fieldset:has(input[name="contenturl"][value="Blog Section"]) .form-group:has(input[name="contenturl"]){
  display: none !important;
}
fieldset:has(input[name="contenturl"][value="Video Section"]) .form-group input[name="content"],
fieldset:has(input[name="contenturl"][value="Video Section"]) .form-group input[name="imageurl"] {
  display: block !important;
}

/* fieldset:has(input[name="contenturl"][value="Gallery Section"]) .form-group input[name="content"],
fieldset:has(input[name="contenturl"][value="Gallery Section"]) .form-group input[name="heading"],
fieldset:has(input[name="contenturl"][value="Gallery Section"]) .form-group input[name="subheading"] {
  display: block; 
}

fieldset:has(input[name="contenturl"][value="Blog Section"]) .form-group input[name="contenturl"] {
  display: none; 
} */
