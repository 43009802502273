.youtube_player{
    margin:50px;
}
.youtube_player .box {
    width: 100%;
    margin: auto;
}

.youtube_player a {
    color: #575757;
}

.youtube_player a:hover {
    color: #FD9644;
    text-decoration: none;
}

.youtube_player .main {
    color: #FD9644;
    opacity: 0.2;
    font-weight: 900;
    margin-bottom: -30px;
}

.youtube_player .neo-video-player {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: auto;
    height:400px;
    border-radius: 10px;
    box-shadow:  15px 15px 29px #cbcccf, 
             -15px -15px 29px #ffffff;
}

.youtube_player .video-control-part, 
.youtube_player .video-control-part > div, 
.youtube_player .video-neu-btn {
    display: flex;
    align-items: center;
}

.youtube_player .video-control-part {
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    opacity: 1;
    padding: 10px 15px;
    background-color: #EFF0F4;
    transition: all .25s ease;
}

.youtube_player .video-control-part.playing {
    opacity: 0;
}

.youtube_player .neo-video-player:hover .video-control-part {
    opacity: 1;
}

.youtube_player .video-neu-btn {
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #EFF0F4;
    box-shadow:  6px 6px 15px #cbcccf, 
                -6px -6px 15px #ffffff;
    width: 40px;
    height: 40px;
    margin: 5px;
}

.youtube_player .video-neu-btn::before {
    content: '';
    background: linear-gradient(145deg, #ffffff, #d7d8dc);
    border-radius: 100%;
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    transition: all .25s ease;
}

.youtube_player .video-neu-btn::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    z-index: 1;
    transition: all .25s ease;
}

.youtube_player .drop-btn > .video-neu-btn {
    background: none;
    box-shadow:  none;
}

.youtube_player .drop-btn > .video-neu-btn::before {
    background: none;
}
.youtube_player iframe{
    height:400px !important;
}
.youtube_player .video-neu-btn:hover::before {
    opacity: 0;
}

.youtube_player .video-neu-btn:hover::after {
    color: #FD9644;
}

/* toggle style */

.youtube_player .play-btn.pause {
    background: linear-gradient(145deg, #e4873d, #ffa149);
}

.youtube_player .play-btn.pause::before {
    opacity: 0;
}

.youtube_player .play-btn.pause::after {
    color: #EFF0F4;
}

.youtube_player .play-btn.pause:hover::after {
    color: #575757;
}

/* set icon */

.youtube_player .play-btn {
    width: 60px;
    height: 60px;
}

.youtube_player .play-btn::after {
    content: '\f04b';
}

.youtube_player .play-btn.pause::after {
    content: '\f04c';
}

.youtube_player .sound-btn::after {
    content: '\f028';
}

.youtube_player .sound-btn.low::after {
    content: '\f027';
}

.youtube_player .sound-btn.mute::after {
    content: '\f6a9';
}

.youtube_player .download-btn::after {
    content: '\f381';
}

.youtube_player .setting-btn::after {
    content: '\f013';
}

.youtube_player .fullscreen-btn::after {
    font-family: "Font Awesome 6 Brands"; /* Ensure you specify the correct family */
  content: "\f167"; /* Unicode for the YouTube icon */
  font-size: 24px; /* Adjust size as needed */
  color: red; /* Optional: Customize the color */
}
.youtube_player svg{
    display: none !important;
}
.youtube_player .video-control-bar-part, 
.youtube_player .bar-bg {
    width: 100%;
}

.youtube_player .bar-bg {
    border-radius: 50px;
    height: 10px;
    background: #EFF0F4;
    box-shadow: inset 2px 2px 4px #cbcccf, inset -2px -2px 4px #ffffff;
    margin: 0 10px;
}

.youtube_player .bar-time {
    position: relative;
    height: 10px;
    border-radius: 50px;
    background: #FD9644;
    box-shadow: inset 3px 3px 6px #d7803a, inset -3px -3px 6px #ffad4e;
    width: 0%;
}

.youtube_player .bar-progress {
    height: 10px;
    width: 100%;
}

.youtube_player .bar-pin {
    position: absolute;
    width: 20px;
    height: 20px;
    background: #EFF0F4;
    box-shadow: 4px 4px 10px #cbcccf, -4px -4px 10px #ffffff;
    border-radius: 50px;
    right: -10px;
    top: -5px;
}

.youtube_player .video-element {
    width: 100%;
    min-width: 100%;
    margin-bottom: -10px;
    height:100%;
}

.youtube_player .box-sound {
    width: 0px;
    opacity: 0;
    overflow: hidden;
    background: #EFF0F4;
    height: 40px;
    padding: 17px 15px 0 40px;
    position: absolute;
    left: 0;
    z-index: 1;
    border-radius: 50px;
    box-shadow: 6px 6px 15px #cbcccf, -6px -6px 15px #ffffff;
    transition: all .25s ease;
}

.youtube_player .drop-btn:hover .box-sound {
    width: 120px;
    opacity: 1;
}

.youtube_player .volume {
    width: 100%;
    height: 5px;
    background: #EFF0F4;
    border-radius: 50px;
    box-shadow: inset 2px 2px 3px #cbcccf, inset -2px -2px 3px #ffffff;
}

.youtube_player .bar-volume {
    width: 100%;
    height: 5px;
    border-radius: 50px;
    background: #FD9644;
    box-shadow: inset 3px 3px 6px #d7803a, inset -3px -3px 6px #ffad4e;
}

.youtube_player .fastFwd-box {
    display: none;
    position: absolute;
    width: max-content;
    top: -40px;
    right: 0;
    background: #eff0f4;
    border: 1px solid #00000026;
    border-radius: 10px;
}

.youtube_player .setting-btn:hover .fastFwd-box {
    display: block;
}

.youtube_player .fastFwd-box::before {
    content: '';
    width: 10px;
    height: 10px;
    background: #eff0f4;
    display: block;
    position: absolute;
    bottom: -6px;
    right: 12px;
    transform: rotate(45deg);
    border: solid #00000026;
    border-width: 0 1px 1px 0;
}

.youtube_player .fastFwd:focus {
    box-shadow: none;
}

.youtube_player .bar-time-box {
    display: none;
    position: absolute;
    width: max-content;
    top: -65px;
    right: -55px;
    background: #eff0f4;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #00000026;
}

.youtube_player .bar-progress:hover .bar-time-box {
    display: block;
}

.youtube_player .bar-time-box::before {
    content: '';
    width: 10px;
    height: 10px;
    background: #eff0f4;
    display: block;
    position: absolute;
    bottom: -6px;
    right: 47%;
    transform: rotate(45deg);
    border: solid #00000026;
    border-width: 0 1px 1px 0;
}


/* Container for the filter buttons */
.filter-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    gap: 15px;
  }
  
  /* Style for each filter button */
  .filter-buttons .btn {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: 2px solid #ddd;
    border-radius: 25px;
    background-color: #fff;
    color: #333;
    transition: all 0.3s ease;
    text-transform: uppercase;
  }
  
  /* Hover effect for the buttons */
  .filter-buttons .btn:hover {
    background-color: #f6e057 !important;
    border-color: #f39c12;
    color: white;
  }
  
  /* Active button (highlighted) */
  .filter-buttons .btn-active {
    background-color: #f39c12;
    border-color: #f6e057;
    color: white;
    font-weight: bold;
  }
  
  /* Active button on hover */
  .filter-buttons .btn-active:hover {
    background-color: #e67e22;
    border-color: #e67e22;
  }
  
  /* Optional: Add a transition effect for smooth hover */
  .filter-buttons .btn {
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  }
  