
  .main_upcoming_news .title {
    background-color: #eeeeee;
    padding: 15px 0px;
    margin-bottom: 20px;
    text-align: center;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .main_upcoming_news h1 {
    margin: 0px;
    color: #212121;
    font-weight: 100;
  }
  
  .main_upcoming_news ul {
    padding: 0;
    border-radius: 2px;
    border: 1px solid rgb(235, 235, 235);
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
    position: relative;
    overflow: hidden;
    /* max-width: 800px; */
     margin: 10px auto;
    font-size: 13px;
    /* font-weight: 600; */
    box-shadow: 0 5px 20px -5px rgb(0 0 0 / 10%) !important;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
  .main_upcoming_news li {
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    /* height: 40px; */
    padding:20px 20px 10px 20px;
    position: relative;
    width: 100%;
    
  }
  
  .main_upcoming_news ul li:nth-child(1) {
    background-color: #ffffff;
  }
  .main_upcoming_news ul li:nth-child(2) {
    background-color: #009688;
  }
  .main_upcoming_news ul li:nth-child(3) {
    background-color: #4caf50;
  }
  .main_upcoming_news ul li:nth-child(4) {
    background-color: #8bc34a;
  }
  .main_upcoming_news ul li:nth-child(5) {
    background-color: #cddc39;
  }
  .main_upcoming_news ul li:nth-child(6) {
    background-color: #ffeb3b;
  }
  .main_upcoming_news ul li:nth-child(7) {
    background-color: #ffc107;
  }
  .main_upcoming_news ul li:nth-child(8) {
    background-color: #ff9800;
  }
  .main_upcoming_news ul li:nth-child(9) {
    background-color: #ff5722;
  }
  .main_upcoming_news ul li:nth-child(10) {
    background-color: #795548;
  }
  .main_upcoming_news ul li:nth-child(11) {
    background-color: #00bcd4;
  }
  .main_upcoming_news ul li:nth-child(12) {
    background-color: #009688;
  }
  .main_upcoming_news ul li:nth-child(13) {
    background-color: #4caf50;
  }
  .main_upcoming_news ul li:nth-child(14) {
    background-color: #8bc34a;
  }
  .main_upcoming_news ul li:nth-child(15) {
    background-color: #cddc39;
  }
  .main_upcoming_news ul li:nth-child(16) {
    background-color: #ffeb3b;
  }
  .main_upcoming_news ul li:nth-child(17) {
    background-color: #ffc107;
  }
  .main_upcoming_news ul li:nth-child(18) {
    background-color: #ff9800;
  }
  .main_upcoming_news ul li:nth-child(19) {
    background-color: #ff5722;
  }
  .main_upcoming_news ul li:nth-child(20) {
    background-color: #795548;
  }
  
  .main_upcoming_news ul li:hover {
    background-color: #d6d6d6;
    cursor: pointer;
  }
  .main_upcoming_news ul li a {
    color: #020a43 !important;
    text-decoration: none;
  }
  .main_upcoming_news ul li a:hover {
    color: #eee;
  }
  .upnews{
      list-style-type: decimal !important;
      visibility: visible !important;
      display: block !important;
      color:black !important;
  }

 .main_upcoming_news .slick-next {
    right: 5px !important;
}
.main_upcoming_news .slick-prev {
    left: 75% !important;
}
.main_upcoming_news .slick-prev:before, .main_upcoming_news .slick-next:before {
    color: #b8b8b8 !important;
}
.main_upcoming_news .slick-next:before {
    content: '→';
}
.main_upcoming_news .slick-prev, .main_upcoming_news .slick-next {
    font-size: 22px;
    line-height: 0;
    position: absolute;
    top: -45px !important;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.main_upcoming_news .slick-next:before {
  font-family: 'slick';
  font-size: 30px !important;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main_upcoming_news .slick-prev:before{
  font-family: 'slick';
  font-size: 30px !important;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .slick-prev {
  left: -45px !important;
} */

.main_upcoming_news .slick-slider .slick-list{
  min-height:580px !important;
}